/* You can add global styles to this file, and also import other style files */
@import '~material-design-icons/iconfont/material-icons.css';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

button:focus {
  outline: none;
}


.app-full-bleed-dialog .mat-dialog-container {
  padding: 12px;
}

/************************ Botón con Spinner **************************/
@keyframes spinnerKeyFrame {
  to {transform: rotate(360deg);}
}

.spinnerButton::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinnerKeyFrame .8s linear infinite;
}
